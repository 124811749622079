import React, { Component } from "react"
import Helmet from 'react-helmet'; 

// Common components & Layout 
import Layout from "../../layouts"; 
import { Container } from "react-bootstrap";

// Individual components that make up the page
import Newsletter from "../../components/newsletter/newsletter";
import Hero from "../../components/common/Hero"; 
import Section from "../../components/common/Section"; 
import SEO from "../../components/common/SEO";

// import "../../static/styles/global.css"

class About extends Component {
  render() {
      return (
          <Layout>
            <Helmet bodyAttributes={{ class: 'about-page' }} />  
            <SEO post={{
              title: "Facts First: About Us",
              description: "Facts First is a nonpartisan group of number crunchers that wanted a better way to measure presidential impact on the economy. Learn more about who we are!"
            }} />     
            <Hero title='About Us'></Hero>
            <Container>       
            <Section name="who_we_are" header="About Us"/>              
            <Newsletter />    			
            </Container>    
          </Layout>
      ); 
  }
}

export default About; 